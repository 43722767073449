(function ($) {
  Drupal.behaviors.basicCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.basic-carousel-formatter', context);
      var useResponsive;
      var respDots;
      var respArrows;

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var arrowColor = arrowsDiv.attr('data-arrow-color');
        var removeArrows = carousel.attr('data-remove-arrows');

        useResponsive = false;
        respDots = false;
        respArrows = true;

        // autoplay settings
        var autoplay = carousel.attr('data-slides-autoplay');

        if (typeof autoplay == 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        // To remove arrows
        if (typeof removeArrows !== 'undefined') {
          arrowsDiv = false;
        }

        // slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'));

        // var largeBreakpoint = {};
        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }

        // slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // responsive - show dots for smaller screens
        var mobileDots = carousel.attr('data-mobile-dots');

        if (typeof mobileDots != 'undefined') {
          useResponsive = true;
          respDots = true;
          respArrows = false;
        }
        // plain with defaults
        var settingsPlain = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay
        };
        // case for custom slides count and dots for mobile
        var settingsResponsive = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: respArrows,
                dots: respDots,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        if (useResponsive) {
          carousel.not('.slick-initialized').slick(settingsResponsive);
        } else {
          carousel.not('.slick-initialized').slick(settingsPlain);
        }

        // Dynamically adding arrow color for carousel through CMS
        if (typeof arrowColor !== 'undefined' && arrowsDiv.length > 0) {
          arrowsDiv.find('.slick-arrow').css({
            color: arrowColor
          });
        }
      });
    }
  };
})(jQuery);
